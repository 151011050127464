import React, {Fragment} from "react";
import {Box} from "@mui/material";
import {SetNextWineBtn} from "../components/SetNextWineBtn";
import {useWSContext} from "../context/WSContext";
import {BottomActionsPanel, FlexTablePanel} from "../components/base";
import CopyLinkBtn from "../components/CopyLinkBtn";
import {findAnswer} from "../help/answerUtils";
import {WineCard} from "../components/wine/WineCard";
import {AnswerCard} from "../components/answer/AnswerCard";

export const LevelResultPanel = () => {
    const {currentWine, users, results} = useWSContext();

    return <Fragment>
        <Box key={"top_actions_panel"} display={"flex"} flexDirection={"row"} width={"100%"}
             justifyContent={"center"} gap={2} p={1}>
            <WineCard key={currentWine.uuid} wine={currentWine} allowDeleteAction={false}/>
        </Box>
        <FlexTablePanel>
            {
                users
                    .filter(user => user.uuid !== currentWine.owner.uuid)
                    .map((user) => {
                        let answer = findAnswer(results, user, currentWine)
                        return <AnswerCard answer={answer} wine={currentWine} user={user} key={`answer_${user.uuid}`}/>;
                    })
            }
        </FlexTablePanel>
        <BottomActionsPanel>
            <CopyLinkBtn/>
            <SetNextWineBtn/>
        </BottomActionsPanel>
    </Fragment>
}