import React, {useRef} from "react";
import {Avatar, Badge, Box, IconButton, Paper, Stack} from "@mui/material";
import {useDefaultContext, useWSContext} from "../../context/WSContext";
import {UserAvatar} from "../../help/avatar";
import {BreakWordTypography} from "../base";
import WineBarIcon from "@mui/icons-material/WineBar";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const getBadge = (user, answer) => {
//    return "✔";
    if (!user) return 0;
    if (user && !answer) return 0;
    if (answer) return "✔";
    return `<b>?</b>`
}

const UserViewCard = ({user, answer}) => {
    const {t} = useDefaultContext();
    const badgeContent = getBadge(user, answer);
    return <Paper elevation={3} sx={{p: 1}}>
        <Stack alignItems="center">
            {
                user ? <Badge color={answer ? "success" : "info"}
                              showZero={false}
                              badgeContent={badgeContent}
                              overlap="circular"
                              anchorOrigin={{
                                  vertical: 'bottom', horizontal: 'right',
                              }}>
                    <UserAvatar user={user} size={48}/>
                </Badge> : <Avatar sx={{width: 48, height: 48}} children={<WineBarIcon/>}/>
            }
            <BreakWordTypography text={user?.username || t("Пустой слот")}/>
        </Stack>
    </Paper>
}

export const UsersPanel = () => {
    const {users, results, currentWine} = useWSContext();
    const scrollable = useRef(null);

    const scrollIt = (toRight) => {
        const scrollLength = 200
        scrollable.current.scrollBy({left: scrollLength * (toRight ? 1 : -1), behavior: "smooth"});
    }

    const fillEmpties = () => {
        const countOfEmptyCards = 25 - users.length;
        const result = []
        if (countOfEmptyCards > 0) {
            for (let i = 0; i < countOfEmptyCards; i++) {
                result.push(<UserViewCard key={i}/>)
            }
        }
        return result
    }

    return <Box display={"flex"} flexDirection={"row"} gap={0.5} width={"100vw"}>
        <IconButton onClick={() => scrollIt(false)}><KeyboardDoubleArrowLeftIcon/></IconButton>
        <Box display={"flex"} flexDirection={"row"} gap={0.5} overflow={"hidden"} ref={scrollable}>
            {
                users.map(user => {
                    const answer = results[user.uuid]?.find(a => a.wine.uuid === currentWine?.uuid)
                    return <UserViewCard answer={answer} user={user} key={user?.uuid}/>
                })
            }
            {
                fillEmpties()
            }
        </Box>
        <IconButton onClick={() => scrollIt(true)}><KeyboardDoubleArrowRightIcon/></IconButton>
    </Box>
}