import React from 'react'
import {Autocomplete, InputAdornment, TextField} from "@mui/material";
import {ErrorMessage} from "formik";
import ReactCountryFlag from "react-country-flag"
import {getLocaleForFields, getOptionLabel} from "../../help/lang";

const onChangeCountry = (value, props) => {
    props.setFieldValue("country", value)
    props.setFieldValue("region", null)
}

export const CountryField = ({props, isSendAnswer, countriesDictionary, t}) => {
    const locale = getLocaleForFields();

    return <Autocomplete id={"country"}
                         name={"country"}
                         fullWidth
                         options={countriesDictionary}
                         renderOption={(props, option) => {
                             const icon = <ReactCountryFlag countryCode={`${option.code}`} svg style={{
                                 fontSize: '1.5em',
                                 lineHeight: '1.5em',
                                 marginRight: '1em'
                             }}/>
                             const text = getOptionLabel(locale, option, t)
                             return <li {...props} key={option.code}>{icon} {text}</li>;
                         }}
                         getOptionKey={(option => option.code)}
                         getOptionLabel={(option) => getOptionLabel(locale, option, t)}
                         value={props.values?.country}
                         onBlur={props.handleBlur}
                         isOptionEqualToValue={(ob1, ob2) => {
                             return ob1?.code === ob2?.code
                         }}
                         disabled={isSendAnswer || false}
                         sx={{mt: 1}}
                         onChange={(e, newValue) => onChangeCountry(newValue, props)}

                         renderInput={(params, value) => {
                             const country = props.values?.country
                             const icon = country?.code ? <InputAdornment position="start">
                                 <ReactCountryFlag countryCode={country.code} svg
                                                   style={{
                                                       fontSize: '1.5em',
                                                       lineHeight: '1.5em',
                                                   }}/>
                             </InputAdornment> : params.InputProps.startAdornment
                             return (<TextField {...params} label={t("Страна")}
                                                helperText={<ErrorMessage name={"country"}/>}
                                                placeholder=""
                                                required={true}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: icon
                                                }}
                                 />
                             )
                         }}

    />
}