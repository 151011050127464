import React from "react";
import {IconButton, TextField} from "@mui/material";
import {ErrorMessage} from "formik";
import {maxPercent, minPercent} from "../../help/constants";
import {AddBox, IndeterminateCheckBox} from "@mui/icons-material";

export const AlcoholField = ({props, isSendAnswer, t}) => {
    return <TextField id={"alcohol"}
                      name={"alcohol"}
                      fullWidth
                      variant="outlined"
                      required
                      label={t("Алкоголь")}
                      value={props.values?.alcohol}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      helperText={<ErrorMessage name={"alcohol"}/>}
                      disabled={isSendAnswer || false}
                      InputProps={{
                          type: "number",
                          sx: {
                              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                  display: 'none'
                              },
                              '& input[type=number]': {
                                  MozAppearance: 'textfield'
                              },
                          },
                          startAdornment: (
                              <IconButton
                                  onClick={() => props.setFieldValue("alcohol", props.values?.alcohol - 0.5)}
                                  disabled={(props.values?.alcohol <= minPercent) || isSendAnswer}>
                                  <IndeterminateCheckBox/>
                              </IconButton>
                          ),
                          endAdornment: (
                              <IconButton
                                  onClick={() => props.setFieldValue("alcohol", props.values?.alcohol + 0.5)}
                                  disabled={(props.values?.alcohol >= maxPercent) || isSendAnswer}>
                                  <AddBox/>
                              </IconButton>
                          ),
                      }}
    />
}