import React from 'react'
import {matchSorter} from 'match-sorter'
import {Autocomplete, TextField} from "@mui/material";
import {ErrorMessage} from "formik";
import {getLocaleForFields, getOptionLabel} from "../../help/lang";

const filterOptions = (options, { inputValue }) => {
    return matchSorter(options, inputValue, {keys: ['ruName', 'enName']});
 };

export const RegionField = ({props,  isSendAnswer, regionsDictionary, t}) => {
    const locale = getLocaleForFields();
    const selectedValues = React.useMemo(
      () => regionsDictionary.filter((v) => v.countryCode === props.values?.country?.code),
      [regionsDictionary,props],
    );
    return <Autocomplete id={"region"}
                         name={"region"}
                         fullWidth
                         options={selectedValues}
                         getOptionLabel={(option) => getOptionLabel(locale, option, t)}
                         value={props.values?.region}
                         onBlur={props.handleBlur}
                         error={props.errors.region && props.touched.region}
                         isOptionEqualToValue={(ob1, ob2) => {
                             return ob1?.code === ob2?.code
                         }}
                         disabled={isSendAnswer || false}
                         sx={{mt: 1}}
                         onChange={(_, value) => props.setFieldValue("region", value)}
                         renderInput={(params) => (
                             <TextField {...params} label={t("Регион")} placeholder=""
                                        helperText={<ErrorMessage name="region"/>}
                             />
                         )}
    />
}