import React from "react";
import {Stack, Chip, Box, Typography, Divider} from "@mui/material";
import {PaperItem} from "../base";
import {getLocaleForFields, getOptionLabel} from "../../help/lang";
import {getArea} from "../../help/fieldsMapping";
import {useDefaultContext} from "../../context/WSContext";
import {UserAvatar} from "../../help/avatar";
import {mapPoints} from "../../help/answerUtils";
import {
    CalendarMonth as CalendarMonthIcon,
    Percent as PercentIcon, WineBar as WineBarIcon,
    LocationOn as LocationOnIcon
} from "@mui/icons-material";

const locale = getLocaleForFields();

const getAreaColor = (a, w) => {
    let color = "success";
    if (a?.country?.code !== w?.country?.code) {
        color = "error";
    } else {
        if (a?.region?.code !== w?.region?.code) {
            color = "secondary"
        }
    }

    return color
}

export const AnswerCard = ({answer, wine, user}) => {

    const {t} = useDefaultContext();

    return <PaperItem key={answer.uuid} elevation={6}>
        <Stack direction={"column"} sx={{p: 1, height: '100%'}} gap={1}>
            <Stack direction={"row"} gap={1} alignItems={"center"} alignContent={"center"}>
                <Box sx={{all: "initial"}}>
                    <UserAvatar user={user} size={48}/>
                </Box>
                <Typography width={"100%"}>{user?.username}</Typography>
                <Typography>{mapPoints(answer?.points, t)}</Typography>
            </Stack>
            <Divider sx={{mt: 1}}/>
            {
                answer?.uuid && <Stack direction={"row"} flexWrap={"wrap"} gap={1} p={1} alignItems={"center"}>
                    {
                        answer?.grapes.map(grape => {
                            const name = getOptionLabel(locale, grape, t);
                            const color = wine.grapes.find(g => g?.code === grape?.code) ? "success" : "error"
                            return <Chip key={`grapes_${answer?.uuid}_${grape?.code}`}
                                         color={color}
                                         icon={<WineBarIcon/>} label={name}/>
                        })
                    }
                    <Chip key={`location_${wine?.uuid}`} icon={<LocationOnIcon/>}
                          color={getAreaColor(answer, wine)}
                          label={getArea(answer, t, locale)}/>
                    <Chip key={`vintage_${answer?.uuid}`} icon={<CalendarMonthIcon/>}
                          color={answer?.vintage && (answer?.vintage === wine?.vintage) ? "success" : "error"}
                          label={answer?.vintage}/>
                    <Chip key={`alcohol_${answer?.uuid}`} icon={<PercentIcon/>}
                          color={answer?.alcohol && (answer?.alcohol === wine?.alcohol) ? "success" : "error"}
                          label={answer?.alcohol}/>
                </Stack>
            }
            {
                !answer?.uuid && <Typography alignContent={"center"} height={"100%"}>{t("Нет ответа")}</Typography>
            }
        </Stack>
    </PaperItem>
}
