import React, {Fragment} from "react";
import {Box} from "@mui/material";
import {useDefaultContext, useWSContext} from "../context/WSContext";
import {BottomActionsPanel} from "../components/base";
import CopyLinkBtn from "../components/CopyLinkBtn";
import {ResultTable} from "../components/ResultTable";
import ReturnToHomeBtn from "../components/ReturnToHomeBtn";

export const FinalResultPanel = () => {

    const {t} = useDefaultContext();
    const {uuid} = useWSContext();

    return <Fragment>
        <Box display={"flex"} flexDirection={"column"} flexGrow={1}>
            <ResultTable/>
        </Box>
        <BottomActionsPanel>
            <CopyLinkBtn uuid={uuid} t={t}/>
            <ReturnToHomeBtn/>
        </BottomActionsPanel>
    </Fragment>
}