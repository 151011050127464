import React from "react";
import {IconButton, TextField} from "@mui/material";
import {ErrorMessage} from "formik";
import {Casino} from "@mui/icons-material";
import {generateWineName} from "../../help/generator";
import {useDefaultContext} from "../../context/WSContext";

const setRandomWineName = (props) => {
    props.setFieldValue("name", generateWineName())
}

export const NameField = ({props, readOnly}) => {
    const {t} = useDefaultContext();
    return <TextField id={"name"} name={"name"} label={t("Название")} required
                      variant="outlined" margin="dense" fullWidth
                      value={props.values.name}
                      disabled={readOnly}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      helperText={<ErrorMessage name={"name"}/>}
                      InputProps={{
                          readOnly: readOnly,
                          endAdornment: (
                              !readOnly && <IconButton onClick={() => setRandomWineName(props)}>
                                  <Casino/>
                              </IconButton>),
                      }}
    />
}
