import React, {Fragment} from "react";
import CopyLinkBtn from "../components/CopyLinkBtn";
import {SetNextWineBtn} from "../components/SetNextWineBtn";
import {useDefaultContext, useWSContext} from "../context/WSContext";
import {WineCard} from "../components/wine/WineCard";
import {AddWineBtn} from "../components/wine/AddWineBtn";
import {
    BottomActionsPanel, FlexTablePanel, TopActionsPanel,
} from "../components/base";
import ReturnToHomeBtn from "../components/ReturnToHomeBtn";
import {UsersPanel} from "../components/user/UsersPanel";

export const AddWinePanel = () => {
    const {t} = useDefaultContext();
    const {wines} = useWSContext();

    return <Fragment>
        <TopActionsPanel>
            <UsersPanel/>
        </TopActionsPanel>
        <FlexTablePanel emptyMessage={`${t("Вы не добавили ни одного вина")} 🍷`}>
            {wines?.map(wine => <WineCard key={wine.uuid} wine={wine} allowDeleteAction={true}/>)}
        </FlexTablePanel>
        <BottomActionsPanel>
            <ReturnToHomeBtn/>
            <CopyLinkBtn/>
            <AddWineBtn/>
            <SetNextWineBtn/>
        </BottomActionsPanel>
    </Fragment>
}